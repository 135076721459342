var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Adding CSS classes to specific dates"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeCustomClass) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("If you need to highlight a specific date or dates, set the ")]), _c('code', [_vm._v("date-info-fn")]), _c('span', [_vm._v(" prop to a reference to a function that returns a CSS class string (or array of strings) to apply to the date's cell. The function is passed two arguments: ")]), _c('code', [_vm._v("ymd")]), _c('span', [_vm._v(" The date as a ")]), _c('code', [_vm._v("YYYY-MM-DD")]), _c('span', [_vm._v(" string, ")]), _c('code', [_vm._v("date")]), _c('span', [_vm._v(" The date as a ")]), _c('code', [_vm._v("Date")]), _c('span', [_vm._v(" object")])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('b-calendar', {
    attrs: {
      "date-info-fn": _vm.dateClass,
      "locale": "en"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }