var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Default slot"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeSlot) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Provide optional content at the bottom of the calendar interface via the use of default slot. The slot can be used to add buttons such as ")]), _c('code', [_vm._v("Select Today")]), _c('span', [_vm._v(" or ")]), _c('code', [_vm._v("Reset")]), _c('span', [_vm._v(", etc.")])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('b-calendar', {
    attrs: {
      "value-as-date": "",
      "locale": "en"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_c('div', {
    staticClass: "d-flex",
    attrs: {
      "dir": "ltr"
    }
  }, [_vm.value ? _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.clearDate
    }
  }, [_vm._v(" Clear date ")]) : _vm._e(), _c('b-button', {
    staticClass: "ml-auto",
    attrs: {
      "size": "sm",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.setToday
    }
  }, [_vm._v(" Set Today ")])], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }