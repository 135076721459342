var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Calendar Variant"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeVariant) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("The selected date button (background color) defaults to the ")]), _c('code', [_vm._v("'primary'")]), _c('span', [_vm._v(" theme variant. You can change this to any of the Bootstrap v4 theme variant colors: ")]), _c('code', [_vm._v("'secondary', 'success', 'danger', 'warning', 'info'")]), _c('span', [_vm._v(", etc, via the ")]), _c('code', [_vm._v("selected-variant")]), _c('span', [_vm._v(" prop.")])]), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-calendar', {
    attrs: {
      "selected-variant": "success",
      "today-variant": "info"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }