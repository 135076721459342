var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Disabling dates"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeDisableDate) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("If you need to disable specific dates within the calendar, specify a function reference to the ")]), _c('code', [_vm._v("date-disabled-fn")]), _c('span', [_vm._v(" prop. The function is passed two arguments: ")]), _c('code', [_vm._v("ymd")]), _c('span', [_vm._v(" The date as a ")]), _c('code', [_vm._v("YYYY-MM-DD")]), _c('span', [_vm._v(" string, ")]), _c('code', [_vm._v("date")]), _c('span', [_vm._v(" The date as a ")]), _c('code', [_vm._v("Date")]), _c('span', [_vm._v(" object")])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('b-calendar', {
    attrs: {
      "date-disabled-fn": _vm.dateDisabled,
      "locale": "en"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }